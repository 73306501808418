module.exports = {
  siteTitle: 'Custom Electric Ltd.', // <title>
  manifestName: 'Spectral',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#f26722',
  manifestThemeColor: '#f26722',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Custom Electric Ltd.',
  subHeading: 'For over 50 years, Custom Electric Ltd. has provided full-service electrical contracting services to commercial, industrial and residential clients throughout Western Canada.',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/nobodythere',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:test@example.com',
    },
  ],
};
